import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideSvgIcons, provideSvgIconsConfig, SvgIconComponent } from '@ngneat/svg-icon';
import { asAclIcon } from './icons/acl';
import { asAlertCircleIcon } from './icons/alert-circle';
import { asAlertHexagonIcon } from './icons/alert-hexagon';
import { asBaseLoadIcon } from './icons/base-load';
import { asBellIcon } from './icons/bell';
import { asBuilding01ExclIcon } from './icons/building-01-excl';
import { asBuilding01Icon } from './icons/building-01';
import { asBuilding02ExclIcon } from './icons/building-02-excl';
import { asBuilding02Icon } from './icons/building-02';
import { asBuilding03Icon } from './icons/building-03';
import { asChatBubbleIcon } from './icons/chat-bubble';
import { asCheckIcon } from './icons/check';
import { asCheckSquareIcon } from './icons/check-square';
import { asChevronDownIcon } from './icons/chevron-down';
import { asChevronLeftIcon } from './icons/chevron-left';
import { asChevronRightIcon } from './icons/chevron-right';
import { asChevronUpIcon } from './icons/chevron-up';
import { asChipIcon } from './icons/chip';
import { asClipboardIcon } from './icons/clipboard';
import { asCogIcon } from './icons/cog';
import { asColdWaterExclIcon } from './icons/cold-water-excl';
import { asColdWaterIcon } from './icons/cold-water';
import { asColdWaterSub2ExclIcon } from './icons/cold-water-sub-2-excl';
import { asColdWaterSub2Icon } from './icons/cold-water-sub-2';
import { asColdWaterSub3ExclIcon } from './icons/cold-water-sub-3-excl';
import { asColdWaterSub3Icon } from './icons/cold-water-sub-3';
import { asColdWaterSub4ExclIcon } from './icons/cold-water-sub-4-excl';
import { asColdWaterSub4Icon } from './icons/cold-water-sub-4';
import { asColdWaterSub5ExclIcon } from './icons/cold-water-sub-5-excl';
import { asColdWaterSub5Icon } from './icons/cold-water-sub-5';
import { asColdWaterSubExclIcon } from './icons/cold-water-sub-excl';
import { asColdWaterSubIcon } from './icons/cold-water-sub';
import { asCoolingExclIcon } from './icons/cooling-excl';
import { asCoolingIcon } from './icons/cooling';
import { asCoolingSub2ExclIcon } from './icons/cooling-sub-2-excl';
import { asCoolingSub2Icon } from './icons/cooling-sub-2';
import { asCoolingSub3ExclIcon } from './icons/cooling-sub-3-excl';
import { asCoolingSub3Icon } from './icons/cooling-sub-3';
import { asCoolingSub4ExclIcon } from './icons/cooling-sub-4-excl';
import { asCoolingSub4Icon } from './icons/cooling-sub-4';
import { asCoolingSub5ExclIcon } from './icons/cooling-sub-5-excl';
import { asCoolingSub5Icon } from './icons/cooling-sub-5';
import { asCoolingSubExclIcon } from './icons/cooling-sub-excl';
import { asCoolingSubIcon } from './icons/cooling-sub';
import { asCrossIcon } from './icons/cross';
import { asCustomerIcon } from './icons/customer';
import { asDotIcon } from './icons/dot';
import { asDotsIcon } from './icons/dots';
import { asDropletsIcon } from './icons/droplets';
import { asEditBoxIcon } from './icons/edit-box';
import { asEnergyIcon } from './icons/energy';
import { asEtDeviationIcon } from './icons/et-deviation';
import { asExcelIcon } from './icons/excel';
import { asFloorIcon } from './icons/floor';
import { asFlowIcon } from './icons/flow';
import { asFullscreenIcon } from './icons/fullscreen';
import { asGasExclIcon } from './icons/gas-excl';
import { asGasIcon } from './icons/gas';
import { asGasSub2ExclIcon } from './icons/gas-sub-2-excl';
import { asGasSub2Icon } from './icons/gas-sub-2';
import { asGasSub3ExclIcon } from './icons/gas-sub-3-excl';
import { asGasSub3Icon } from './icons/gas-sub-3';
import { asGasSub4ExclIcon } from './icons/gas-sub-4-excl';
import { asGasSub4Icon } from './icons/gas-sub-4';
import { asGasSub5ExclIcon } from './icons/gas-sub-5-excl';
import { asGasSub5Icon } from './icons/gas-sub-5';
import { asGasSubExclIcon } from './icons/gas-sub-excl';
import { asGasSubIcon } from './icons/gas-sub';
import { asHandleChevronLeftIcon } from './icons/handle-chevron-left';
import { asHandleChevronRightIcon } from './icons/handle-chevron-right';
import { asHandleHorizontalIcon } from './icons/handle-horizontal';
import { asHeatingExclIcon } from './icons/heating-excl';
import { asHeatingIcon } from './icons/heating';
import { asHeatingSub2ExclIcon } from './icons/heating-sub-2-excl';
import { asHeatingSub2Icon } from './icons/heating-sub-2';
import { asHeatingSub3ExclIcon } from './icons/heating-sub-3-excl';
import { asHeatingSub3Icon } from './icons/heating-sub-3';
import { asHeatingSub4ExclIcon } from './icons/heating-sub-4-excl';
import { asHeatingSub4Icon } from './icons/heating-sub-4';
import { asHeatingSub5ExclIcon } from './icons/heating-sub-5-excl';
import { asHeatingSub5Icon } from './icons/heating-sub-5';
import { asHeatingSubExclIcon } from './icons/heating-sub-excl';
import { asHeatingSubIcon } from './icons/heating-sub';
import { asHelpIcon } from './icons/help';
import { asHelpSquareIcon } from './icons/help-square';
import { asHotWaterExclIcon } from './icons/hot-water-excl';
import { asHotWaterIcon } from './icons/hot-water';
import { asHotWaterSub2ExclIcon } from './icons/hot-water-sub-2-excl';
import { asHotWaterSub2Icon } from './icons/hot-water-sub-2';
import { asHotWaterSub3ExclIcon } from './icons/hot-water-sub-3-excl';
import { asHotWaterSub3Icon } from './icons/hot-water-sub-3';
import { asHotWaterSub4ExclIcon } from './icons/hot-water-sub-4-excl';
import { asHotWaterSub4Icon } from './icons/hot-water-sub-4';
import { asHotWaterSub5ExclIcon } from './icons/hot-water-sub-5-excl';
import { asHotWaterSub5Icon } from './icons/hot-water-sub-5';
import { asHotWaterSubExclIcon } from './icons/hot-water-sub-excl';
import { asHotWaterSubIcon } from './icons/hot-water-sub';
import { asKpiIcon } from './icons/kpi';
import { asLineChartIcon } from './icons/line-chart';
import { asListIcon } from './icons/list';
import { asLockIcon } from './icons/lock';
import { asMeterExclIcon } from './icons/meter-excl';
import { asMeterGroupIcon } from './icons/meter-group';
import { asMeterIcon } from './icons/meter';
import { asMeterSub2ExclIcon } from './icons/meter-sub-2-excl';
import { asMeterSub2Icon } from './icons/meter-sub-2';
import { asMeterSub3ExclIcon } from './icons/meter-sub-3-excl';
import { asMeterSub3Icon } from './icons/meter-sub-3';
import { asMeterSub4ExclIcon } from './icons/meter-sub-4-excl';
import { asMeterSub4Icon } from './icons/meter-sub-4';
import { asMeterSub5ExclIcon } from './icons/meter-sub-5-excl';
import { asMeterSub5Icon } from './icons/meter-sub-5';
import { asMeterSubExclIcon } from './icons/meter-sub-excl';
import { asMeterSubIcon } from './icons/meter-sub';
import { asMoneyIcon } from './icons/money';
import { asMoveHandleIcon } from './icons/move-handle';
import { asNoovaLogoIcon } from './icons/noova-logo';
import { asPeakLoadIcon } from './icons/peak-load';
import { asPencilIcon } from './icons/pencil';
import { asPlusIcon } from './icons/plus';
import { asProductionMeterExclIcon } from './icons/production-meter-excl';
import { asProductionMeterIcon } from './icons/production-meter';
import { asProductionMeterSub2ExclIcon } from './icons/production-meter-sub-2-excl';
import { asProductionMeterSub2Icon } from './icons/production-meter-sub-2';
import { asProductionMeterSub3ExclIcon } from './icons/production-meter-sub-3-excl';
import { asProductionMeterSub3Icon } from './icons/production-meter-sub-3';
import { asProductionMeterSub4ExclIcon } from './icons/production-meter-sub-4-excl';
import { asProductionMeterSub4Icon } from './icons/production-meter-sub-4';
import { asProductionMeterSub5ExclIcon } from './icons/production-meter-sub-5-excl';
import { asProductionMeterSub5Icon } from './icons/production-meter-sub-5';
import { asProductionMeterSubExclIcon } from './icons/production-meter-sub-excl';
import { asProductionMeterSubIcon } from './icons/production-meter-sub';
import { asResourceIcon } from './icons/resource';
import { asRoomIcon } from './icons/room';
import { asSearchIcon } from './icons/search';
import { asSectionIcon } from './icons/section';
import { asSplitHorizontalIcon } from './icons/split-horizontal';
import { asSplitVerticalIcon } from './icons/split-vertical';
import { asSquareIcon } from './icons/square';
import { asStarFilledIcon } from './icons/star-filled';
import { asStarIcon } from './icons/star';
import { asSubsubMeterIcon } from './icons/subsub-meter';
import { asSubsubsubMeterIcon } from './icons/subsubsub-meter';
import { asSwitchArrowsIcon } from './icons/switch-arrows';
import { asTableIcon } from './icons/table';
import { asThermometerIcon } from './icons/thermometer';
import { asTrashIcon } from './icons/trash';
import { asUsersIcon } from './icons/users';
import { asVirtualMeterIcon } from './icons/virtual-meter';
import { asWaterExclIcon } from './icons/water-excl';
import { asWaterIcon } from './icons/water';
import { asWaterSub2ExclIcon } from './icons/water-sub-2-excl';
import { asWaterSub2Icon } from './icons/water-sub-2';
import { asWaterSub3ExclIcon } from './icons/water-sub-3-excl';
import { asWaterSub3Icon } from './icons/water-sub-3';
import { asWaterSub4ExclIcon } from './icons/water-sub-4-excl';
import { asWaterSub4Icon } from './icons/water-sub-4';
import { asWaterSub5ExclIcon } from './icons/water-sub-5-excl';
import { asWaterSub5Icon } from './icons/water-sub-5';
import { asWaterSubExclIcon } from './icons/water-sub-excl';
import { asWaterSubIcon } from './icons/water-sub';
import { asZoneIcon } from './icons/zone';
import { asArrowUpIcon } from './icons/arrow-up';
import { asArrowDownIcon } from './icons/arrow-down';

/**
 * Manages all icons in the suite.
 *
 * To add an icon, first add the desired svg to ./assets.
 * Then run `bun run svg` to generate the ts icon.
 * Then add your icon to `provideSvgIcons` below.
 * You can then use icons like:
 *
 * ```html
 * <svg-icon key="star"></svg-icon>
 * ```
 */
@NgModule({
  imports: [CommonModule, SvgIconComponent],
  providers: [
    provideSvgIconsConfig({
      sizes: {
        xxs: '12px',
        xs: '16px',
        sm: '20px',
        md: '24px',
        lg: '28px',
        xl: '32px',
        xxl: '36px',
      },
      defaultSize: 'md',
    }),
    provideSvgIcons([
      asAclIcon,
      asAlertCircleIcon,
      asAlertHexagonIcon,
      asArrowDownIcon,
      asArrowUpIcon,
      asBaseLoadIcon,
      asBellIcon,
      asBuilding01ExclIcon,
      asBuilding01Icon,
      asBuilding02ExclIcon,
      asBuilding02Icon,
      asBuilding03Icon,
      asChatBubbleIcon,
      asCheckIcon,
      asCheckSquareIcon,
      asChevronDownIcon,
      asChevronLeftIcon,
      asChevronRightIcon,
      asChevronUpIcon,
      asChipIcon,
      asClipboardIcon,
      asCogIcon,
      asColdWaterExclIcon,
      asColdWaterIcon,
      asColdWaterSub2ExclIcon,
      asColdWaterSub2Icon,
      asColdWaterSub3ExclIcon,
      asColdWaterSub3Icon,
      asColdWaterSub4ExclIcon,
      asColdWaterSub4Icon,
      asColdWaterSub5ExclIcon,
      asColdWaterSub5Icon,
      asColdWaterSubExclIcon,
      asColdWaterSubIcon,
      asCoolingExclIcon,
      asCoolingIcon,
      asCoolingSub2ExclIcon,
      asCoolingSub2Icon,
      asCoolingSub3ExclIcon,
      asCoolingSub3Icon,
      asCoolingSub4ExclIcon,
      asCoolingSub4Icon,
      asCoolingSub5ExclIcon,
      asCoolingSub5Icon,
      asCoolingSubExclIcon,
      asCoolingSubIcon,
      asCrossIcon,
      asCustomerIcon,
      asDotIcon,
      asDotsIcon,
      asDropletsIcon,
      asEditBoxIcon,
      asEnergyIcon,
      asEtDeviationIcon,
      asExcelIcon,
      asFloorIcon,
      asFlowIcon,
      asFullscreenIcon,
      asGasExclIcon,
      asGasIcon,
      asGasSub2ExclIcon,
      asGasSub2Icon,
      asGasSub3ExclIcon,
      asGasSub3Icon,
      asGasSub4ExclIcon,
      asGasSub4Icon,
      asGasSub5ExclIcon,
      asGasSub5Icon,
      asGasSubExclIcon,
      asGasSubIcon,
      asHandleChevronLeftIcon,
      asHandleChevronRightIcon,
      asHandleHorizontalIcon,
      asHeatingExclIcon,
      asHeatingIcon,
      asHeatingSub2ExclIcon,
      asHeatingSub2Icon,
      asHeatingSub3ExclIcon,
      asHeatingSub3Icon,
      asHeatingSub4ExclIcon,
      asHeatingSub4Icon,
      asHeatingSub5ExclIcon,
      asHeatingSub5Icon,
      asHeatingSubExclIcon,
      asHeatingSubIcon,
      asHelpIcon,
      asHelpSquareIcon,
      asHotWaterExclIcon,
      asHotWaterIcon,
      asHotWaterSub2ExclIcon,
      asHotWaterSub2Icon,
      asHotWaterSub3ExclIcon,
      asHotWaterSub3Icon,
      asHotWaterSub4ExclIcon,
      asHotWaterSub4Icon,
      asHotWaterSub5ExclIcon,
      asHotWaterSub5Icon,
      asHotWaterSubExclIcon,
      asHotWaterSubIcon,
      asKpiIcon,
      asLineChartIcon,
      asListIcon,
      asLockIcon,
      asMeterExclIcon,
      asMeterGroupIcon,
      asMeterIcon,
      asMeterSub2ExclIcon,
      asMeterSub2Icon,
      asMeterSub3ExclIcon,
      asMeterSub3Icon,
      asMeterSub4ExclIcon,
      asMeterSub4Icon,
      asMeterSub5ExclIcon,
      asMeterSub5Icon,
      asMeterSubExclIcon,
      asMeterSubIcon,
      asMoneyIcon,
      asMoveHandleIcon,
      asNoovaLogoIcon,
      asPeakLoadIcon,
      asPencilIcon,
      asPlusIcon,
      asProductionMeterExclIcon,
      asProductionMeterIcon,
      asProductionMeterSub2ExclIcon,
      asProductionMeterSub2Icon,
      asProductionMeterSub3ExclIcon,
      asProductionMeterSub3Icon,
      asProductionMeterSub4ExclIcon,
      asProductionMeterSub4Icon,
      asProductionMeterSub5ExclIcon,
      asProductionMeterSub5Icon,
      asProductionMeterSubExclIcon,
      asProductionMeterSubIcon,
      asResourceIcon,
      asRoomIcon,
      asSearchIcon,
      asSectionIcon,
      asSplitHorizontalIcon,
      asSplitVerticalIcon,
      asSquareIcon,
      asStarFilledIcon,
      asStarIcon,
      asSubsubMeterIcon,
      asSubsubsubMeterIcon,
      asSwitchArrowsIcon,
      asTableIcon,
      asThermometerIcon,
      asTrashIcon,
      asUsersIcon,
      asVirtualMeterIcon,
      asWaterExclIcon,
      asWaterIcon,
      asWaterSub2ExclIcon,
      asWaterSub2Icon,
      asWaterSub3ExclIcon,
      asWaterSub3Icon,
      asWaterSub4ExclIcon,
      asWaterSub4Icon,
      asWaterSub5ExclIcon,
      asWaterSub5Icon,
      asWaterSubExclIcon,
      asWaterSubIcon,
      asZoneIcon,
    ]),
  ],
  exports: [SvgIconComponent],
})
export class IconModule {}

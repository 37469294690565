import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, inject, NgModule, provideAppInitializer } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AUTH_TOKEN, AuthModule, AuthService } from '@logic-suite/shared/auth';
import { CacheModule } from '@logic-suite/shared/cache';
import { LoaderInterceptor, LoaderModule } from '@logic-suite/shared/components/loader';
import { ErrorHandlerModule } from '@logic-suite/shared/error';
import { createTranslateLoader, I18nRootModule, START_OF_WEEK } from '@logic-suite/shared/i18n';
import { BREADCRUMB_TOKENIZER } from '@logic-suite/shared/nav/breadcrumb';
import { assignEnv, getEnv } from '@logic-suite/shared/utils';

import { Router } from '@angular/router';
import { FeatureFlagDirective } from '@logic-suite/shared/feature-flag/feature-flag.directive';
import { FeatureFlagService } from '@logic-suite/shared/feature-flag/feature-flag.service';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import * as Sentry from '@sentry/angular';
import { HierarchyComponent } from '@suite/hierarchy-feature';
import { IconModule } from '@suite/icon';
import { RailComponent } from '@suite/rail';
import { providePrimeNG } from 'primeng/config';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { CustomerInterceptor } from './shared/access';
import { AppMenuModule } from './shared/nav/app-menu';
import { NodeAndUrlTokenizer } from './shared/nav/breadcrumb-assetnode.tokenizer';
import { MenuModule } from './shared/nav/menu';
import { SettingsModule } from './shared/settings_old';

// Expose environment variables to the window object under __env
// Azure environment variables will be exposed to the same object after /api/env.js responds,
// and will overwrite any values from environment
assignEnv(environment);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient] },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, // getEnv('production'),
      // Register the ServiceWorker immediately.
      registrationStrategy: 'registerImmediately',
      scope: '/',
    }),
    SettingsModule,
    AuthModule,
    CacheModule,
    LoaderModule,
    AppMenuModule,
    I18nRootModule,
    ErrorHandlerModule,
    AppRoutes,
    RailComponent,
    FeatureFlagDirective,
    HierarchyComponent,
    IconModule,
    MenuModule,
  ],
  providers: [
    { provide: AUTH_TOKEN, useValue: getEnv('auth') },
    { provide: START_OF_WEEK, useValue: 1 },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: BREADCRUMB_TOKENIZER, useClass: NodeAndUrlTokenizer },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: HTTP_INTERCEPTORS, useClass: CustomerInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    // region Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    // Sentry trace
    provideAppInitializer(() => {
      const initializerFn = ((sentry: Sentry.TraceService) => () => {})(inject(Sentry.TraceService));

      return initializerFn();
    }),
    // Client settings sync with backend
    provideAppInitializer(async () => {
      const storage = inject(ApplicationStorageService);
      const auth = inject(AuthService);
      await auth.isLoggedIn$.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        filter((isLoggedIn) => isLoggedIn === true),
      );

      // Sync from DB after user is known
      return await storage.loadFromDb();
    }),
    // endregion
    providePrimeNG({
      theme: {
        preset: definePreset(Aura, {
          components: {
            inputtext: {
              root: {
                sm: {
                  paddingY: '0.25rem',
                },
              },
            },
            tabs: {
              tabpanel: {
                padding: '1rem 0 0 0',
              },
            },
          },
        }),
        options: {
          darkModeSelector: '.dark',
        },
      },
    }),
  ],
})
export class AppModule {
  // TODO(11954): This is a hackish way of getting the feature flags to load.
  // Come up with a better way!
  private featureFlagService = inject(FeatureFlagService);
}
